import { getFormProps, getInputProps, useForm } from "@conform-to/react"
import { getZodConstraint, parseWithZod } from "@conform-to/zod"
import {
	json,
	type ActionFunctionArgs,
	type LoaderFunctionArgs,
	type MetaFunction,
} from "@remix-run/node"
import { Form, Link, useActionData, useSearchParams } from "@remix-run/react"
import { Trans, useTranslation } from "react-i18next"
import { HoneypotInputs } from "remix-utils/honeypot/react"
import { z } from "zod"
import { GeneralErrorBoundary } from "#app/components/error-boundary.tsx"
import { CheckboxField, ErrorList, TextField } from "#app/components/forms.tsx"
import { Spacer } from "#app/components/spacer.tsx"
import { StatusButton } from "#app/components/ui/status-button.tsx"
import { APP_NAME } from "#app/constants.ts"
import { login, requireAnonymous } from "#app/utils/auth.server.ts"
import { ProviderConnectionForm, providerNames } from "#app/utils/connections.tsx"
import { checkHoneypot } from "#app/utils/honeypot.server.ts"
import { useIsPending } from "#app/utils/misc.tsx"
import { PasswordSchema, UsernameSchema } from "#app/utils/user-validation.ts"
import { handleNewSession } from "./login.server.ts"

const LoginFormSchema = z.object({
	username: UsernameSchema,
	password: PasswordSchema,
	redirectTo: z.string().optional(),
	remember: z.boolean().optional(),
})

export async function loader({ request }: LoaderFunctionArgs) {
	await requireAnonymous(request)
	return json({})
}

export async function action({ request }: ActionFunctionArgs) {
	await requireAnonymous(request)
	const formData = await request.formData()
	checkHoneypot(formData)
	const submission = await parseWithZod(formData, {
		schema: intent =>
			LoginFormSchema.transform(async (data, ctx) => {
				if (intent !== null) return { ...data, session: null }

				const session = await login(data)
				if (!session) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Invalid username or password",
					})
					return z.NEVER
				}

				return { ...data, session }
			}),
		async: true,
	})

	if (submission.status !== "success" || !submission.value.session) {
		return json(
			{ result: submission.reply({ hideFields: ["password"] }) },
			{ status: submission.status === "error" ? 400 : 200 },
		)
	}

	const { session, remember, redirectTo } = submission.value

	return handleNewSession({
		request,
		session,
		remember: remember ?? false,
		redirectTo,
	})
}

export default function LoginPage() {
	const { t } = useTranslation()
	const actionData = useActionData<typeof action>()
	const isPending = useIsPending()
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get("redirectTo")

	const [form, fields] = useForm({
		id: "login-form",
		constraint: getZodConstraint(LoginFormSchema),
		defaultValue: { redirectTo },
		lastResult: actionData?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: LoginFormSchema })
		},
		shouldRevalidate: "onBlur",
	})

	const signupRedirect = redirectTo ? `/signup?${encodeURIComponent(redirectTo)}` : "/signup"

	return (
		<div className="flex h-full w-full flex-col justify-center pb-32 pt-20">
			<div className="mx-auto w-full max-w-md">
				<div className="flex flex-col gap-3 text-center">
					<h1 className="text-h1">{t("login.heading")}</h1>
					<p className="text-body-md text-muted-foreground">{t("login.subtitle")}</p>
				</div>
				<Spacer size="xs" />

				<div className="mx-auto w-full max-w-md px-8">
					<Form method="POST" {...getFormProps(form)}>
						<HoneypotInputs />
						<TextField
							label={t("user.username")}
							{...getInputProps(fields.username, { type: "text" })}
							autoFocus={true}
							autoComplete="username"
							errors={fields.username.errors}
						/>

						<TextField
							label={t("user.password")}
							{...getInputProps(fields.password, { type: "password" })}
							autoComplete="current-password"
							errors={fields.password.errors}
						/>

						<div className="flex justify-between">
							<CheckboxField
								label={t("login.remember-me")}
								{...getInputProps(fields.remember, { type: "checkbox" })}
								errors={fields.remember.errors}
							/>
							<div>
								<Link
									to="/forgot-password"
									className="link text-body-xs font-semibold"
								>
									{t("login.forgot-password")}
								</Link>
							</div>
						</div>

						<input {...getInputProps(fields.redirectTo, { type: "hidden" })} />
						<ErrorList errors={form.errors} id={form.errorId} />

						<div className="flex items-center justify-between gap-6 pt-3">
							<StatusButton
								className="w-full"
								status={isPending ? "pending" : form.status ?? "idle"}
								type="submit"
								disabled={isPending}
							>
								{t("login.submit")}
							</StatusButton>
						</div>
					</Form>
					<ul className="mt-5 flex flex-col gap-5 border-b-2 border-t-2 border-border py-3">
						{providerNames.map(providerName => (
							<li key={providerName}>
								<ProviderConnectionForm
									type="Login"
									providerName={providerName}
									redirectTo={redirectTo}
								/>
							</li>
						))}
					</ul>
					<div className="flex items-center justify-center gap-2 pt-6">
						<Trans
							i18nKey="login.signup-link"
							components={[
								<span key={0} className="text-muted-foreground" />,
								<Link key={1} to={signupRedirect} className="link" />,
							]}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export function meta(): ReturnType<MetaFunction> {
	return [{ title: `Login to ${APP_NAME}` }]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
